<template>
  <div class="app" @click="touchDisplay">
    <!--<video width="100%" height="100%" muted :poster="require(`@/assets/img/main/${preview}`)" autoplay loop><source :src="require(`@/assets/video/${video}`)" type="video/mp4" codecs="mp4" /></video>-->
    <swiper
      class="blocks"
      :modules="[Autoplay, EffectFade]"
      :autoplay="autoplay"
      effect="fade"
      :slides-per-view="1"
      :space-between="0"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="image in images" :key="image.id">
        <img
          :src="require(`@/assets/img/main/${image.img}`)"
          :alt="image.img"
        />
      </swiper-slide>
      <div class="text">{{ text }}</div>
    </swiper>
    <div class="touch">{{ touch }}</div>
  </div>
</template>

<script>
import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
export default {
  name: "t-main",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      EffectFade,
      Autoplay,
    };
  },
  data() {
    return {
      /*video: `fps.mp4`,
      preview: `1.jpg`,*/
      images: [
        {
          id: 1,
          img: "pause.png",
        },
        {
          id: 2,
          img: "recept.jpg",
        },
        {
          id: 3,
          img: "mk.jpg",
        },
        {
          id: 4,
          img: "coffee_machine.png",
        },
        {
          id: 5,
          img: "zilant.jpg",
        },
        {
          id: 6,
          img: "tur_game.jpg",
        },
        {
          id: 7,
          img: "yandex.png",
        },
        {
          id: 8,
          img: "limonade.png",
        },
        {
          id: 9,
          img: "breakfast.jpg",
        },
        {
          id: 10,
          img: "new1.png",
        },
        {
          id: 11,
          img: "new2.png",
        },
        {
          id: 12,
          img: "new3.png",
        },
        {
          id: 13,
          img: "new4.png",
        },
        {
          id: 14,
          img: "new5.png",
        },
      ],
      effect: "fade",
      grabCursor: true,
      loop: true,
      centeredSlides: true,
      slidesPerView: "auto",
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
      now: new Date(),
      touch: "Коснись, чтобы начать",
      text: "Место для рекламного макета",
    };
  },
  methods: {
    touchDisplay() {
      this.$router.push("/welcome");
    },
  },
  computed: {
    currentDate() {
      return (
        this.now.getDate() +
        "." +
        this.now.getMonth() +
        1 +
        "." +
        this.now.getFullYear()
      );
    },
  },
  mounted() {
    localStorage.removeItem("orderId");
    let host = window.location.href;
    let cafe_slug = host.split("/");
    this.cafe_slug = cafe_slug[2].split(".")[0];
    if (host.indexOf("localhost") != -1) {
      this.cafe_slug = "it-park";
    }
    console.log(this.cafe_slug);
    if (this.cafe_slug == "pushkina") {
      this.images.splice(7, 1);
    }
  },
};
</script>

<style lang="scss">
.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #d9d9d9;
  .touch,
  .text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .touch {
    bottom: 0;
    width: 100%;
    height: 232px;
    background-color: #fff;
    font-weight: 700;
    font-size: 48px;
    line-height: 65.47px;
    color: #000;
    z-index: 1;
    box-shadow: 1px 3px 25px 0px rgba(0, 45, 29, 0.1);
  }
  .text {
    top: 0;
    bottom: 232px;
    left: 0;
    right: 0;
    color: #717171;
    font-size: 20px;
    line-height: 24px;
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .footer {
    position: absolute;
    bottom: 0;
  }
}
</style>
